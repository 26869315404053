import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async getHospitals () {
		const response = await _axios.get('hospital');
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getHospitalById (id) {
		const response = await _axios.get(`/hospital/${id}`);
		if (response.data.resFlag) {
			const userJson = response.data.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async createHospital (body) {
		const response = await _axios.post('hospital', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async editHospital (body) {
		const response = await _axios.put(`hospital/${body.id}`, body);
		if (response.data.resFlag) {
			const userJson = response.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async deleteHospital (id) {
		const response = await _axios.delete(`hospital/${id}`);
		if (response.data.resFlag) {
			// const userJson = response.data.data;
			const userJson = response;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async getHospitalAdmins () {
		var response = [];
		await _axios.get('/user/gethospitaladmins').then((res) => {
			response = res.data;
		}).catch((err) => {
			if (err.response) {
				response = err.response; // => the response payload
			}
		});
		return response.data;
	},
	async assignUserToHospital (body) {
		// {
		// 	"HospitalId":1,
		// 	"UserId":853
		// }
		const response = await _axios.post('hospital/assignusertohospital', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async deassignUserFromHospital (body) {
		// {
		// 	"HospitalId":80,
		// 	"UserId":852
		// }
		const response = await _axios.post('hospital/deassignuserfromhospital', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async assignAdminToHospital (body) {
		const response = await _axios.post('hospital/assignadmintohospital', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async deassignAdminToHospital (body) {
		const response = await _axios.post('hospital/deassignadminfromhospital', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async assignPatientToHospital (body) {
		const response = await _axios.post('hospital/assignpatienttohospital', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async assignDoctorToHospital (body) {
		const response = await _axios.post('hospital/assigndoctortohospital', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getRelatedUsersForHospital (hospitalId) {
		// const response = await _axios.get('hospital/getrelatedusersforhospital', { headers: { hospitalId: hospitalId } });
		const response = await _axios.get('hospital/getrelatedusersforhospitalv2', { headers: { hospitalId: hospitalId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async supportmessage () {
		const response = await _axios.get('supportmessage/getdistinctgroups');
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async sendSupportMessage (body) {
		const response = await _axios.post('supportmessage', body);
		if (response.status === 201) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async changeSupportMessageStatus (body) {
		const response = await _axios.post('supportmessage/setstatus', body);
		if (response.status === 201) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async sendFileSupportMessage (body) {
		const response = await _axios.post('/supportmessage/addfile', body.FormDataSend,
			{
				headers: { RelatedToSupportMessageId: body.RelatedToSupportMessageId, 'Content-Type': 'multipart/form-data' },
			});
		if (response.status === 200) {
			return response.data;
		} else {
			throw new UsersError('Could not upload image!');
		}
	},

	async roomDetails (id) {
		const response = await _axios.get('supportmessage/getmessagesbygroupid', { headers: { SupportMessageId: id } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},

	async getRelatedPatients (hospitalId) {
		// const response = await _axios.get('hospital/getrelatedpatientsforhospital', { headers: { hospitalId: hospitalId } });
		// hospital/getrelatedpatientsforhospitalv2 on the response we are not returning medical teams
		const response = await _axios.get('hospital/getrelatedpatientsforhospitalv2', { headers: { hospitalId: hospitalId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getRelatedMedicalStaff (hospitalId) {
		const response = await _axios.get('hospital/getrelatedmedicalstaffforhospital', { headers: { hospitalId: hospitalId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getRelatedPatientsv2 (hospitalId) {
		const response = await _axios.get('hospital/getrelatedpatientsforhospitalv2', { headers: { hospitalId: hospitalId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getRelatedPatientsV3 (hospitalId) {
		// const response = await _axios.get('hospital/getrelatedpatientsforhospitalv3', { headers: { hospitalId: hospitalId } });
		const response = await _axios.get('hospital/getrelatedpatientsforhospitalv4', { headers: { hospitalId: hospitalId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
};
