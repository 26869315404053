import Repository from '../../repositories/RepositoryFactory';

const RolesRepository = Repository.get('roles');

export default {
	namespaced: true,
	state: {
		roles: null,
	},
	mutations: {
		GET_ROLES (state, payload) {
			state.roles = payload.data;
		},
	},
	actions: {
		async getRoles ({ commit }) {
			commit('GET_ROLES', await RolesRepository.getRoles());
		},
	},
	getters: {
		getRoles (state) {
			let roles = state.roles?.filter(item => item.id === 3 || item.id === 4 || item.id === 7);
			roles = roles || []; // If roles is undefined, use an empty array instead
			roles.unshift({ id: -99, roleNameForDisplay: 'All', roleNameForDisplayESP: 'Toda' });
			return roles;
		},
		getRolesForForm (state) {
			return state.roles?.filter(item => item.id === 3 || item.id === 4 || item.id === 7);
		},
	},
};
