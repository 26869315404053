import Repository from '../../repositories/RepositoryFactory';

const MedicalTeamRepository = Repository.get('medicalteam');

export default {
	namespaced: true,
	state: {
		medicalTeam: [],
		medicalStaff: [],
		// memberList: [],
		medicalTeamsRelated: [],
		selectedMedicalTeam: [], // its wrong to have it this as array, it should be only object of medicalTeam
	},
	mutations: {
		LOAD_MEDICAL_TEAMS (state, payload) {
			state.medicalTeamsRelated = payload.medicalTeamsData;
			state.medicalTeam = payload.medicalTeamsData;
			// state.memberList = payload.medicalTeamsData.map(team => ({ membersList: team.membersList }));
		},
		// REMOVE_MEDICAL_TEAM (state, payload) {
		// 	state.medicalTeam = payload.medicalTeamsData;
		// },
		GET_MEDICAL_TEAM (state, payload) {
			state.medicalTeam = payload.medicalTeamsData;
			// state.memberList = payload.medicalTeamsData.map(team => ({ membersList: team.membersList }));

			// state.selectedMedicalTeam = [];
			// state.selectedMedicalTeam.push(payload.medicalTeamsData);
			state.selectedMedicalTeam = payload.medicalTeamsData;
		},
		SELECTED_MEDICAL_TEAM (state, payload) {
			// state.selectedMedicalTeam = payload;
			state.selectedMedicalTeam = [];
			state.selectedMedicalTeam.push(payload);
		},
	},
	actions: {
		async createMedicalTeam ({ commit }, body) {
			const res = await MedicalTeamRepository.createMedicalTeam(body);
			return res;
		},
		async assignMemberToMedicalTeam ({ commit }, body) {
			const res = await MedicalTeamRepository.assignMemberToMedicalTeam(body);
			return res;
		},
		async deassignMemberToMedicalTeam ({ commit }, body) {
			const res = await MedicalTeamRepository.deassignMemberToMedicalTeam(body);
			return res;
		},
		async assignMultipleMembersToMedicalTeam ({ commit }, body) {
			// {
			// 	"MedicalTeamId":687,
			// 	"MemberUserIdList":[1056,1058]
			// }
			const res = await MedicalTeamRepository.assignMultipleMembersToMedicalTeam(body);
			return res;
		},
		async deassignMultipleMembersFromMedicalTeam ({ commit }, body) {
			// {
			// 	"MedicalTeamId":687,
			// 	"MemberUserIdList":[1056,1058]
			// }
			const res = await MedicalTeamRepository.deassignMultipleMembersFromMedicalTeam(body);
			return res;
		},
		async editMedicalTeam ({ commit }, body) {
			const res = await MedicalTeamRepository.editMedicalTeam(body);
			return res;
		},

		// DELETE ACTION FOR MEDICALTEAM COMPONENT
		// async deleteMedicalTeam ({ commit }, body) {
		// 	const res = await MedicalTeamRepository.deleteMedicalTeam(body);
		// 	return res;
		// },

		async getMedicalTeamForPatient ({ commit }, id) {
			commit('GET_MEDICAL_TEAM', await MedicalTeamRepository.getMedicalTeamForPatient(id));
		},
		async getMedicalTeamForDoctor ({ commit }, data) {
			const res = await MedicalTeamRepository.getMedicalTeamForDoctor(data.nurseId, data.hospitalId);
			commit('LOAD_MEDICAL_TEAMS', res);
			return res;
		},
		async getMedicalTeamForNurse ({ commit }, data) {
			const res = await MedicalTeamRepository.getMedicalTeamForNurse(data.nurseId, data.hospitalId);
			commit('LOAD_MEDICAL_TEAMS', res);
			return res;
		},
		async getMedicalTeamForHospitalAdmin ({ commit }, id) {
			const res = await MedicalTeamRepository.getMedicalTeamForHospitalAdmin(id);
			commit('LOAD_MEDICAL_TEAMS', res);
			return res;
		},
	},
	getters: {
		getMedicalTeam (state) {
			// return state?.medicalTeam;
			return state?.selectedMedicalTeam;
		},
	},
};
